.overflow-y-auto {
    overflow-y: auto;
  }
  
  .bg-white {
    background-color: white;
  }
  
  .py-3 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }
  
  .md:py-8 {
    /* Media query equivalent to Tailwind's "md" breakpoint */
    @media (min-width: 768px) {
      padding-top: 2rem;
      padding-bottom: 2rem;
    }
  }
  
  .mx-auto {
    margin-left: auto;
    margin-right: auto;
  }
  
  .max-w-7xl {
    max-width: 80rem; /* Typically 1280px */
  }
  
  .px-2 {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  
  .md:px-4 {
    @media (min-width: 768px) {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }
  
  .max-w-4xl {
    max-width: 56rem; /* Typically 896px */
  }
  
  .text-center {
    text-align: center;
  }
  
  .mt-5 {
    margin-top: 1.25rem;
  }
  
  .text-xl {
    font-size: 1.25rem;
  }
  
  .font-bold {
    font-weight: bold;
  }
  
  .tracking-tight {
    letter-spacing: -0.025em;
  }
  
  .text-gray-900 {
    color: #1F2937;
  }
  
  .md:text-3xl {
    @media (min-width: 768px) {
      font-size: 1.875rem;
    }
  }
  
  .isolate {
    isolation: isolate;
  }
  
  .mt-24 {
    margin-top: 6rem;
  }
  
  .md:mt-12 {
    @media (min-width: 768px) {
      margin-top: 3rem;
    }
  }
  
  .flex {
    display: flex;
  }
  
  .items-center {
    align-items: center;
  }
  
  .justify-center {
    justify-content: center;
  }
  
  .cursor-pointer {
    cursor: pointer;
  }
  
  .flex-col {
    flex-direction: column;
  }
  
  .justify-between {
    justify-content: space-between;
  }
  
  .rounded-3xl {
    border-radius: 1.5rem;
  }
  
  .p-5 {
    padding: 1.25rem;
  }
  
  .ring-1 {
    /* This assumes a default ring offset and color. This might need adjustments. */
    box-shadow: 0 0 0 1px #3B82F6;
  }
  
  .ring-indigo-200 {
    --ring-color: #BFDBFE; /* This is assuming the ring utility is working with this variable, adjustments might be needed */
    box-shadow: 0 0 0 1px #BFDBFE;
  }
  
  .xl:p-8 {
    @media (min-width: 1280px) {
      padding: 2rem;
    }
  }
  
  .gap-x-4 {
    column-gap: 1rem;
  }
  
  .text-base {
    font-size: 1rem;
  }
  
  .font-semibold {
    font-weight: 600;
  }
  
  .leading-6 {
    line-height: 1.5rem;
  }
  
  .text-indigo-600 {
    color: #4F46E5;
  }
  
  .md:text-lg {
    @media (min-width: 768px) {
      font-size: 1.125rem;
    }
  }
  
  .md:leading-8 {
    @media (min-width: 768px) {
      line-height: 2rem;
    }
  }
  
  .mt-6 {
    margin-top: 1.5rem;
  }
  
  .text-2xl {
    font-size: 1.5rem;
  }
  
  .md:text-4xl {
    @media (min-width: 768px) {
      font-size: 2.25rem;
    }
  }
  
  .space-y-3 > :not(template) ~ :not(template) {
    margin-top: 0.75rem;
  }
  
  .text-sm {
    font-size: 0.875rem;
  }
  
  .leading-6 {
    line-height: 1.5rem;
  }
  
  .text-gray-600 {
    color: #6B7280;
  }
  
  .md:mt-8 {
    @media (min-width: 768px) {
      margin-top: 2rem;
    }
  }
  
  .flex-none {
    flex: none;
  }
  
  .h-6 {
    height: 1.5rem;
  }
  
  .w-5 {
    width: 1.25rem;
  }
  
  .mt-5 {
    margin-top: 1.25rem;
  }
  
  .rounded-md {
    border-radius: 0.375rem;
  }
  
  .bg-indigo-600 {
    background-color: #4F46E5;
  }
  
  .px-3 {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
  
  .py-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  
  .text-center {
    text-align: center;
  }
  
  .text-sm {
    font-size: 0.875rem;
  }
  
  .text-white {
    color: #ffffff;
  }
  
  .shadow-sm {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  }
  
  .hover\:bg-indigo-500:hover {
    background-color: #3B82F6;
  }
  
  .focus-visible\:outline:focus-visible {
    outline: 2px solid transparent;
  }
  
  .focus-visible\:outline-offset-2:focus-visible {
    outline-offset: 0.5rem;
  }
  
  .focus-visible\:outline-indigo-600:focus-visible {
    outline-color: #4F46E5;
  }
  
  .md:mt-8 {
    @media (min-width: 768px) {
      margin-top: 2rem;
    }
  }