@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

.tabs {
    box-sizing: border-box;
    height: 75px;
    border-top: 1px solid rgba(255, 255, 255, 0.815);
    background-color: #DFEDF2;
}

.tab-button {
    background-color: #DFEDF2;
}

.tab-label {
    font-family: 'Libre Caslon Text';
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
    color: #4E4D4F;
    margin-bottom: 0px;
    margin-top: 0px;
}

.tab-image-ion {
    color: #486B90;
    font-size: 24px;
}

@media only screen and (min-width: 768px) {
    .tabs {
        height: 85px;
    }

    .tab-label {
        font-size: 12px;
        line-height: 17px;
    }

    .tab-image-ion {
        margin-bottom: 7px;
    }
}