.container-created-demo {
  background-image: url(../../public/assets/images/background.webp);
  background-color: #ffffff;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  overflow: auto;
}

.content-created-demo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 40px 28px 0px 28px;
}

.label-created-demo {
  font-family: "Libre Caslon Display";
  font-style: normal;
  font-weight: 400;
  font-size: 21px;
  line-height: 24px;
  color: #1a1a1a;
  text-align: center;
}

.petition-subject-created-demo {
  height: 363px;
  width: 100%;
  font-family: "Almarose Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 19px;
  text-align: center;
  color: #222222;
  padding: 10px 10px;
  overflow-y: auto;
  border: none;
  background-color: #f9fcfd;
  white-space: pre-line;
}

.header-created-demo {
  font-family: "Almarose Semibold";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 19px;
  color: #222222;
  display: block;
  margin: 5px 0 10px 0;
}

.button-download-demo {
  height: 50px;
  width: 100%;
  background: rgba(32, 136, 23, 0.88);
  font-family: "Libre Caslon Text";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 15px;
  margin-top: 20px;
}

.button-signature-demo {
  height: 50px;
  width: 100%;
  background: rgba(23, 136, 75, 0.88);
  font-family: "Libre Caslon Text";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 15px;
}

.blur-text-demo {
  color: transparent;
  text-shadow: 0 0 7px rgba(0, 0, 0, 0.5);
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10 and IE 11 */
  user-select: none;
  /* Standard syntax */
}

.icon-demo {
  font-size: 24px;
}

.text-center-demo {
  text-align: center;
  font-weight: 700;
  font-size: 20px;
  margin-bottom: 0px;
}

.stripe-modal-demo {
  width: 100%;
  height: 100%;
  /* z-index: 9999; */
  /* padding: 150px 10px; */
}

.text-center-button-demo {
  height: 36px;
  width: 180px;
  font-family: sohne-var, "Helvetica Neue", Arial, sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 14px;
  /* margin: 0px !important; */
  margin-right: 1rem;
}

.text-center-button-demo-payment {
  height: 36px;
  width: 180px;
  font-family: sohne-var, "Helvetica Neue", Arial, sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 14px;
  /* margin: 0px !important; */
}

.text-center-disclaimer-demo {
  font-size: 15px;
  font-weight: 400;
  line-height: 14px;
  text-align: center;
  color: #000;
}

.strong-demo {
  text-decoration: underline;
  cursor: pointer;
  font-weight: 700;
}

.stripe-connect {
  background: #635bff;
  display: inline-block;
  height: 38px;
  text-decoration: none;
  width: 180px;

  border-radius: 4px;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;

  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;

  -webkit-font-smoothing: antialiased;
}

.stripe-connect span {
  color: #ffffff;
  display: block;
  font-family: sohne-var, "Helvetica Neue", Arial, sans-serif;
  font-size: 15px;
  font-weight: 400;
  line-height: 14px;
  padding: 13px 0px 0px 38px;
  position: relative;
  text-align: center;
}

.stripe-connect:hover {
  background: #7a73ff;
}

.stripe-connect span::after {
  background-repeat: no-repeat;
  background-size: 50.58px;
  content: "";
  height: 20px;
  left: 20%;
  position: absolute;
  top: 28.95%;
  width: 49.58px;
}

/* Logos */
.stripe-connect span::after {
  background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 23.0.4, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 468 222.5' style='enable-background:new 0 0 468 222.5;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill-rule:evenodd;clip-rule:evenodd;fill:%23FFFFFF;%7D%0A%3C/style%3E%3Cg%3E%3Cpath class='st0' d='M414,113.4c0-25.6-12.4-45.8-36.1-45.8c-23.8,0-38.2,20.2-38.2,45.6c0,30.1,17,45.3,41.4,45.3 c11.9,0,20.9-2.7,27.7-6.5v-20c-6.8,3.4-14.6,5.5-24.5,5.5c-9.7,0-18.3-3.4-19.4-15.2h48.9C413.8,121,414,115.8,414,113.4z M364.6,103.9c0-11.3,6.9-16,13.2-16c6.1,0,12.6,4.7,12.6,16H364.6z'/%3E%3Cpath class='st0' d='M301.1,67.6c-9.8,0-16.1,4.6-19.6,7.8l-1.3-6.2h-22v116.6l25-5.3l0.1-28.3c3.6,2.6,8.9,6.3,17.7,6.3 c17.9,0,34.2-14.4,34.2-46.1C335.1,83.4,318.6,67.6,301.1,67.6z M295.1,136.5c-5.9,0-9.4-2.1-11.8-4.7l-0.1-37.1 c2.6-2.9,6.2-4.9,11.9-4.9c9.1,0,15.4,10.2,15.4,23.3C310.5,126.5,304.3,136.5,295.1,136.5z'/%3E%3Cpolygon class='st0' points='223.8,61.7 248.9,56.3 248.9,36 223.8,41.3 '/%3E%3Crect x='223.8' y='69.3' class='st0' width='25.1' height='87.5'/%3E%3Cpath class='st0' d='M196.9,76.7l-1.6-7.4h-21.6v87.5h25V97.5c5.9-7.7,15.9-6.3,19-5.2v-23C214.5,68.1,202.8,65.9,196.9,76.7z'/%3E%3Cpath class='st0' d='M146.9,47.6l-24.4,5.2l-0.1,80.1c0,14.8,11.1,25.7,25.9,25.7c8.2,0,14.2-1.5,17.5-3.3V135 c-3.2,1.3-19,5.9-19-8.9V90.6h19V69.3h-19L146.9,47.6z'/%3E%3Cpath class='st0' d='M79.3,94.7c0-3.9,3.2-5.4,8.5-5.4c7.6,0,17.2,2.3,24.8,6.4V72.2c-8.3-3.3-16.5-4.6-24.8-4.6 C67.5,67.6,54,78.2,54,95.9c0,27.6,38,23.2,38,35.1c0,4.6-4,6.1-9.6,6.1c-8.3,0-18.9-3.4-27.3-8v23.8c9.3,4,18.7,5.7,27.3,5.7 c20.8,0,35.1-10.3,35.1-28.2C117.4,100.6,79.3,105.9,79.3,94.7z'/%3E%3C/g%3E%3C/svg%3E");
}

@media only screen and (min-width: 768px) {
  .content-created-demo {
    width: 600px;
    margin: 0 auto;
  }

  .label-created-demo {
    font-size: 24px;
    line-height: 29px;
  }

  .petition-subject-created-demo {
    width: 100%;
    height: 363px;
    padding: 20px 29px 0 20px;
    font-weight: 400;
    font-size: 18px;
    line-height: 19px;
  }

  .button-signature-demo,
  .button-download-demo {
    height: 65px;
    font-size: 18px;
    line-height: 20px;
  }

  /* width */
  ::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #c9c8c8;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #b1afaf;
  }
}

@media only screen and (max-width: 768px) {
  .stripe-badge-image {
    width: 85% !important;
  }
}