.container-create {
  background-image: url(../../public/assets/images/background.webp);
  background-color: #ffffff;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100%;
  overflow: auto;
}

.content-create {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 40px 28px 0px 28px;
}

.label-create {
  font-family: "Libre Caslon Display";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #1a1a1a;
  text-align: center;
}

.label-create-center {
  font-family: "Libre Caslon Display";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  color: #1a1a1a;
  text-align: center;
  position: relative;
}

.label-create-center::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 0; /* Çizginin başlangıç genişliği */
  height: 2px; /* Çizginin yüksekliği */
  background-color: #316095; /* Çizgi rengi */
  animation: drawLine 1s forwards; /* Çizgiyi oluşturan animasyon */
}

@keyframes drawLine {
  from {
    width: 0; /* Çizgi başlangıç genişliği */
  }
  to {
    width: 100%; /* Çizgi bitiş genişliği */
  }
}


.petition-info-create {
  width: 100%;
  height: 50px;
  background: rgba(255, 255, 255, 0.6);
  border: 1px solid rgba(174, 174, 174, 0.2);
  padding: 10px 29px 10px 20px;
  font-family: "Almarose Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 19px;
  color: rgba(34, 34, 34, 0.8);
  margin-bottom: 5px;
}

.petition-subject-create {
  width: 100%;
  height: 363px;
  background: rgba(255, 255, 255, 0.6);
  border: 1px solid rgba(174, 174, 174, 0.2);
  padding: 20px 29px 0 20px;
  font-family: "Almarose Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 19px;
  overflow-y: auto;
  color: rgba(34, 34, 34, 0.8);
}

.created-button-create {
  width: 100%;
  height: 55px;
  background: rgba(23, 75, 136, 0.88) !important;
  border-top: 1px solid #ffffff;
  color: #ffffff;
  font-family: "Libre Caslon Text";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.2em;
  margin-top: 15px;
}

.kvkk-wrapper {
  display: flex;
  align-items: center;
  margin: 10px 0 0 0;
}

.petition-info-kvkk-label {
  font-family: "Libre Caslon Text";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 19px;
  color: rgba(34, 34, 34, 0.8);
  margin: 0 0 0 5px;
}

.petition-info-kvkk-label span {
  text-decoration: underline;
  cursor: pointer;
}

#kvkk-checkbox {
  width: 15px;
  height: 15px;
  margin: 0 0 0 5px;
  accent-color: rgba(23, 75, 136, 0.88);
}

.loading-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loading-wrapper p {
  text-align: center;
}

.dots {
  width: 3.5em;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;
  margin-top: 15px;
}

.dots div {
  width: 0.8em;
  height: 0.8em;
  border-radius: 50%;
  background-color: #0c376a;
  animation: fade 0.8s ease-in-out alternate infinite;
}

.dots div:nth-of-type(1) {
  animation-delay: -0.4s;
}

.dots div:nth-of-type(2) {
  animation-delay: -0.2s;
}

@keyframes fade {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@media only screen and (min-width: 768px) {
  .content-create {
    width: 600px;
    margin: 0 auto;
  }

  .label-create {
    font-size: 24px;
    line-height: 29px;
  }
  
  .label-create-center {
    font-size: 24px;
    line-height: 29px;
  }

  .petition-info-create {
    width: 100%;
    height: 70px;
    padding: 10px 29px 10px 20px;
    font-size: 18px;
    line-height: 19px;
    margin-bottom: 10px;
  }

  .petition-subject-create {
    width: 100%;
    height: 363px;
    padding: 20px 29px 0 20px;
    font-weight: 400;
    font-size: 18px;
    line-height: 19px;
  }

  #kvkk-checkbox {
    width: 20px;
    height: 20px;
  }

  .petition-info-kvkk-label {
    font-size: 16px;
    line-height: 19px;
    margin: 0 0 0 5px;
  }

  /* width */
  ::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #c9c8c8;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #b1afaf;
  }
}

::-webkit-calendar-picker-indicator {
  filter: invert(1);
}

input[type="date"] {
  display: flex;
  text-align: left;
  -webkit-appearance: none;
  -moz-appearance: none;
}

/* Date Picker  placeholder*/

input[type="date"]::before {
  content: attr(placeholder) !important;
  color: #b1afaf;
  margin-right: 0.5em;
}

/* input[type="date"]:focus::before {
  content: "" !important;
}

input[type="date"]:focus {
  outline: none;
} */