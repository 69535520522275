.container-my-petitions {
    background-image: url(../../public/assets/images/background.webp);
    background-color: #ffffff;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    overflow: auto;
}

.contents-my-petitions {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 40px 28px 0px 28px;
    height: 450px;
}

.contents-not-my-petitions {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding: 90px 28px 0 28px;
    height: 450px;
}

.label-my-petitions {
    font-family: 'Libre Caslon Display';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: #1A1A1A;
    text-align: center;
}

.header-my-petitions {
    width: 100%;
    height: 40px;
    background: rgba(222, 230, 239, 0.7);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 10px;
    font-family: 'Almarose Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #222222;
}

.list-ul {
    padding: 0;
    margin: 0;
    overflow-y: auto;
    height: 245px;
}

.see-pdf-my-petitions {
    display: flex;
    align-items: center;
    color: #486B90;
    margin-bottom: 5px;
}


.list-item {
    list-style: none;
    font-family: 'Almarose Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 19px;
    color: #222222;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
    padding: 0 10px;
    border-bottom: 1px solid #a8a9aa;
}

.icon-my-petitions {
    font-size: 24px;
    cursor: pointer;
    margin: 0 10px;
}

.show-modal {
    width: 100%;
    height: 100%;
    padding: 50px 5px;
}

.spinner-my-petitions {
    position: relative;
    width: 3em;
    height: 3em;
    border: 4px solid #0C376A;
    overflow: hidden;
    animation: spin 3s ease infinite;
    margin-top: 10px;
}

.spinner-my-petitions::before {
    content: '';
    position: absolute;
    top: -3px;
    left: -3px;
    width: 3em;
    height: 3em;
    background-color: #0C376A;
    transform-origin: center bottom;
    transform: scaleY(1);
    animation: fill 3s linear infinite;
}

@keyframes spin {

    50%,
    100% {
        transform: rotate(360deg);
    }
}

@keyframes fill {

    25%,
    50% {
        transform: scaleY(0);
    }

    100% {
        transform: scaleY(1);
    }
}


@media only screen and (min-width: 768px) {
    .contents-my-petitions {
        width: 550px;
        height: 500px;
        margin: 0 auto;
    }

    .label-my-petitions {
        font-size: 24px;
        line-height: 23px;
    }

    .header-my-petitions {
        height: 55px;
        font-size: 16px;
    }

    .list-item {
        font-size: 14px;
    }

    .list-text-right {
        font-size: 14px;
    }

    /* width */
    ::-webkit-scrollbar {
        width: 10px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: #f1f1f1;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #c9c8c8;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #b1afaf;
    }
}