.container-kvkk {
    height: 100%;
}

.content-kvkk {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px 5px 10px 5px;
}

.label-kvkk {
    font-family: 'Libre Caslon Display';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: #1A1A1A;
    text-align: center;
    padding-bottom: 10px;
}

.ul-kvkk {
    padding-left: 15px;
}

.petition-subject-kvkk {
    height: 100vh;
    width: 100%;
    font-family: 'Almarose Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-align: left;
    color: #222222;
    padding: 10px 10px;
    border: none;
    background-color: #F9FCFD;
    white-space: pre-line;
}

.text-center-kvkk {
    text-align: left;
    font-weight: 500;
    margin-bottom: 0;
    font-size: 16px;
    line-height: 20px;
}

@media only screen and (min-width: 768px) {
    .content-kvkk {
        max-width: 600px;
        margin: 0 auto;
    }

    .label-kvkk {
        font-size: 24px;
        line-height: 29px;
    }

    .petition-subject-kvkk {
        height: 515px;
        font-size: 18px;
        line-height: 19px;
    }

    /* width */
    ::-webkit-scrollbar {
        width: 10px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: #f1f1f1;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #c9c8c8;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #b1afaf;
    }
}