.container-help {
    background-image: url(../../public/assets/images/background.webp);
    background-color: #ffffff;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    overflow: auto;
}

.content-help {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 40px 25px 0px 25px;
}

.accordion-button-help {
    border-bottom: 1px solid #096c91;
}

.accordion-button-label{
    font-size: 15px !important;
}

.accordion-container-help {
    margin: 20px 0 20px 0;
}

.accordion-content-help{
    color: #000000;
    padding: 10px 20px;
    font-size: 14px;
}

.label-help {
    font-family: 'Libre Caslon Display';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: #1A1A1A;
    text-align: center;
}

.petition-subject-help {
    height: 363px;
    width: 100%;
    font-family: 'Almarose Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 19px;
    text-align: left;
    color: #222222;
    padding: 10px 10px;
    overflow-y: auto;
    border: none;
    background-color: #F9FCFD;
    white-space: pre-line;
}

.text-center-help {
    text-align: center;
    font-weight: 700;
    margin-bottom: 0;
    font-size: 20px;
}

@media only screen and (min-width: 768px) {
    .content-help {
        width: 600px;
        margin: 0 auto;
    }

    .label-help {
        font-size: 24px;
        line-height: 29px;
    }

    .petition-subject-help {
        height: 515px;
        font-size: 18px;
        line-height: 19px;
    }


    /* width */
    ::-webkit-scrollbar {
        width: 10px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: #f1f1f1;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #c9c8c8;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #b1afaf;
    }
}