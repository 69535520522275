.container-created-original {
    background-image: url(../../public/assets/images/background.webp);
    background-color: #ffffff;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    overflow: auto;
}

.content-created-original {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 40px 28px 0px 28px;
}

.label-created-original {
    font-family: 'Libre Caslon Display';
    font-style: normal;
    font-weight: 400;
    font-size: 21px;
    line-height: 24px;
    color: #1A1A1A;
    text-align: center;
}

.petition-subject-created-original {
    height: 363px;
    width: 100%;
    font-family: 'Almarose Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 19px;
    text-align: center;
    color: #222222;
    padding: 10px 10px;
    overflow-y: auto;
    border: none;
    background-color: #F9FCFD;
    white-space: pre-line;
}

.header-created-original {
    font-family: 'Almarose Semibold';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 19px;
    color: #222222;
    display: block;
    margin: 5px 0 10px 0;
}

.button-download-original {
    height: 50px;
    width: 100%;
    background: rgba(32, 136, 23, 0.88);
    font-family: 'Libre Caslon Text';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 15px;
    margin-top: 20px;
}

.button-signature-original {
    height: 50px;
    width: 100%;
    background: rgba(23, 136, 75, 0.88);
    font-family: 'Libre Caslon Text';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 15px;
    margin-top: 20px;
}

.icon-original {
    font-size: 24px;
}

.text-header-original {
    font-weight: 700;
    font-size: 20px;
    color: #222222;
}

.text-details-original{
    margin: 15px 0;
}

.text-right-original {
    text-align: right;
    margin: 0 10px 0 0;
}

.text-left-original {
    text-align: left;
    margin: 20px 0 0 10px;
}

@media only screen and (min-width: 768px) {
    .content-created-original {
        width: 600px;
        margin: 0 auto;
    }

    .label-created-original {
        font-size: 24px;
        line-height: 29px;
    }

    .petition-subject-created-original {
        width: 100%;
        height: 363px;
        padding: 20px 29px 0 20px;
        font-weight: 400;
        font-size: 18px;
        line-height: 19px;
    }

    .button-signature-original, .button-download-original {
        height: 65px;
        font-size: 18px;
        line-height: 20px;
    }

    /* width */
    ::-webkit-scrollbar {
        width: 10px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: #f1f1f1;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #c9c8c8;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #b1afaf;
    }
}