.container-success {
    background-image: url(../../public/assets/images/background.webp);
    background-color: #ffffff;
    background-repeat: no-repeat;
    background-size: cover;
    height: 100%;
    overflow: auto;
}

.content-success {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 200px 28px 0px 28px;
}

.label-success {
    font-family: 'Libre Caslon Display';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: #0C376A;
    text-align: center;
}

.text-success {
    font-family: 'Libre Caslon Display';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: #0C376A;
    text-align: center;
}

.spinner {
    position: relative;
    width: 3em;
    height: 3em;
    border: 4px solid #0C376A;
    overflow: hidden;
    animation: spin 3s ease infinite;
    margin-top: 10px;
}

.spinner::before {
    content: '';
    position: absolute;
    top: -3px;
    left: -3px;
    width: 3em;
    height: 3em;
    background-color: #0C376A;
    transform-origin: center bottom;
    transform: scaleY(1);
    animation: fill 3s linear infinite;
}

@keyframes spin {

    50%,
    100% {
        transform: rotate(360deg);
    }
}

@keyframes fill {

    25%,
    50% {
        transform: scaleY(0);
    }

    100% {
        transform: scaleY(1);
    }
}

@media only screen and (min-width: 768px) {
    .content-success {
        width: 600px;
        margin: 0 auto;
    }

    .label-success {
        font-size: 24px;
        line-height: 29px;
    }

    .text-success {
        font-size: 18px;
        line-height: 19px;
    }

    /* width */
    ::-webkit-scrollbar {
        width: 10px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: #f1f1f1;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #c9c8c8;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #b1afaf;
    }
}